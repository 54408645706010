import React, { useContext, useState } from 'react'
import { fetchBackend } from '../helpers/fetch';
import { useTranslation } from 'react-i18next';
import Typewriter from 'typewriter-effect';
import { Icon } from '@iconify/react';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { ElementContext } from './ElementContext';
import TemplateModal from './modals/TemplateModal';
import { useAuth } from '@frontegg/react';
import '../styles/message-generator.css'


const WhatsappMessageGenerator = () => {
    
    const { setTemplateModal, globalEnv, userPlan, aiTemplatesGeneratedCount, setAiTemplatesGeneratedCount } = useContext(ElementContext);
    const [recipient, setRecipient] = useState('');
    const [topic, setTopic] = useState('');
    const [messageLength, setMessageLength] = useState(400);
    const [generatedMessage, setGeneratedMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();
    
    const handleRecipientChange = (event) => {
        setRecipient(event.target.value);
    };
    
    const handleTopicChange = (event) => {
        setTopic(event.target.value);
    };
    
    const handleMessageLengthChange = (event) => {
        setMessageLength(parseInt(event.target.value));
    };

    const handleSaveTemplate = () => {
        setTemplateModal(true)
    }
    
    const handleGenerateMessage = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        const email = user.email
        const body = { recipient, topic, messageLength, email }

        try {
            const response = await fetchBackend('message-generator',body ,'POST')
            const responseJson = await response.json()
            // console.log('responseJson:', responseJson)
            const generatedText = await responseJson.generatedResponse.content
            const totalGenerados = aiTemplatesGeneratedCount + 1
            setAiTemplatesGeneratedCount( totalGenerados );
            setGeneratedMessage(generatedText);
        } catch (error) {
            console.error(error);
            setGeneratedMessage(t('errorGeneratingMessage'));
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className='home'>
            <div className="container-fluid">
                <div className="row pb-5">
                    <h2 className='text-mediumblue mt-3 mb-0'>{ t('aiTemplates') }</h2>
                    <p className='subtitle mb-3'>
                        { t('aiTemplatesSubtitle') }
                    </p>

                    <div className="col-6 d-flex justify-content-center align-items-center">
                        <form onSubmit={handleGenerateMessage}>
                            <div className="ai-container">
                                <div className="ai-brand-logo"></div>
                                <h5 className="ai-brand-title">{ t('templatesGenerator') }</h5>
                                <div className="ai-inputs">
                                    <div className="row mt-2">
                                        <div className="col-8 d-flex flex-column justify-content-end pe-1">
                                            <label className='ai-label mb-2' htmlFor='recipient'>
                                                { t('targetedMessage') }
                                            </label>
                                            <input
                                                autoComplete='off'
                                                id="recipient"
                                                value={recipient}
                                                onChange={handleRecipientChange}
                                                className='ai-input'
                                                type="text"
                                                placeholder={ t('adressedTo') }
                                                required
                                            />
                                        </div>
                                        <div className="col-4 d-flex flex-column justify-content-end  ps-1">
                                            <div className='d-flex align-items-center mb-2'>
                                                <label className='ai-label label-height-line' htmlFor='messageLength'>
                                                    { t('maxTokens') }
                                                </label>
                                                <Tooltip TransitionComponent={Zoom} title={ t('tokensExplanation') }>
                                                    <Icon icon="mdi:question-mark-circle-outline" className='mg-info-icon' />
                                                </Tooltip>
                                            </div>
                                            <input
                                                className='ai-input'
                                                type="number"
                                                min={50}
                                                max={500}
                                                id="messageLength"
                                                value={messageLength}
                                                onChange={handleMessageLengthChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <div className='d-flex align-items-center mb-2'>
                                                <label className='ai-label' htmlFor='topic'>
                                                    { t('messageAbout') }
                                                </label>
                                                <Tooltip TransitionComponent={Zoom} title={ t('messageTopicTooltip') }>
                                                    <Icon icon="mdi:information-variant-circle-outline" className='mg-info-icon' />
                                                </Tooltip>
                                            </div>
                                            <textarea
                                                autoComplete='off'
                                                className='ai-textarea'
                                                type="text"
                                                placeholder={ t('topicPlaceholder') }
                                                id="topic"
                                                value={topic}
                                                onChange={handleTopicChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <section className="d-flex justify-content-center">
                                        <button
                                            className='ai-button'
                                            type="submit"
                                            disabled={isLoading || aiTemplatesGeneratedCount >= globalEnv.PLANS[userPlan].maxAiTemplates}
                                        >
                                            {
                                                isLoading
                                                ? `${t('generatingMessage')}...`
                                                : t('generateMessage')
                                            }
                                        </button>
                                    </section>
                                </div>
                                
                                <small className='d-flex justify-content-end messages-counter'>
                                    {aiTemplatesGeneratedCount} de {globalEnv.PLANS[userPlan].maxAiTemplates}
                                </small>
                            </div>
                        </form>
                    </div>
                    <div className="col-6 d-flex justify-content-center align-items-center">
                            {generatedMessage ? (
                                <div className="res-container">
                                    <h5 className="res-brand-title">{ t('generatedMessage') }</h5>
                                    <Typewriter
                                        options={{
                                            strings: generatedMessage,
                                            autoStart: true,
                                            loop: false,
                                            delay: 50
                                        }}
                                    />

                                    <button
                                        className='res-button mt-auto'
                                        type="submit"
                                        onClick={ handleSaveTemplate }
                                    >
                                        { t('saveTemplate') }
                                    </button>
                                </div>
                            ) : (
                                <div className="res-container d-flex justify-content-center align-items-center">
                                    <p className='res-text'>
                                        { t('messagesNoGenerated') }
                                    </p>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            
            <TemplateModal buttonSelected={'savetemplate'} message={generatedMessage} type={'ai-saved'} />
        </div>
    )
}

export default WhatsappMessageGenerator